import {quantum} from 'ldrs'
import Markdown from 'react-markdown';

quantum.register(); 

export function ChuckPresent ({messages, pending, messages2, pending2, question, setQuestion, submit, onKeyDown}) {
    let style = {
        margin:'10px',
        width:'100%',
        height:'680px',
    };
    return (
        <div style={style}>
            <div style={{width:'100%', height:'100%', padding:'10px', backgroundColor:'#edeff1'}}>

                <div style={{gap:'10px', display:'grid', gridTemplateColumns:'1fr 1fr'}}>
                    <div id="msgContainer" style={{minHeight:'95%', maxHeight:'95%', height:'95%', overflowY:'scroll'}}>
                        { messages.map((message, i) =>
                            <div key={i} style={{textAlign:(i%2===0) ? 'right':'left'}}>
                                <div 
                                    className="markdown" 
                                    style={{
                                        display: 'inline-block',
                                        borderRadius: '5px', 
                                        margin: '10px', 
                                        padding: '10px', 
                                        border: '1px solid black', 
                                        color: (i%2===0) ? 'black':'white', 
                                        backgroundColor: (i%2===0) ? '#f3f5f6':'#0e172f'
                                    }}
                                > 
                                    { (i%2===1) && <Markdown>{message}</Markdown> }
                                    { (i%2===0) && message }
                                </div>
                            </div>
                        )}
                        { pending && <div style={{textAlign:'center'}}><l-quantum size="45" speed="1.75" color="black" /></div> }
                    </div>
                    <div id="msgContainer2" style={{minHeight:'95%', maxHeight:'95%', height:'95%', overflowY:'scroll'}}>
                        { messages2.map((message, i) =>
                            <div key={i} style={{textAlign:(i%2===0) ? 'right':'left'}}>
                                <div 
                                    className="markdown" 
                                    style={{
                                        display: 'inline-block',
                                        borderRadius: '5px', 
                                        margin: '10px', 
                                        padding: '10px', 
                                        border: '1px solid black', 
                                        color: (i%2===0) ? 'black':'white', 
                                        backgroundColor: (i%2===0) ? '#f3f5f6':'#0e172f'
                                    }}
                                > 
                                    { (i%2===1) && <Markdown>{message}</Markdown> }
                                    { (i%2===0) && message }
                                </div>
                            </div>
                        )}
                        { pending2 && <div style={{textAlign:'center'}}><l-quantum size="45" speed="1.75" color="black" /></div> }
                    </div>
                </div>

                <div>
                    <input onKeyDown={onKeyDown} style={{border:'1px solid black', width:'70%', display:'inline', padding:'5px', backgroundColor:'#f2f4f8', color:'#0e172f', borderRadius:'5px'}} disabled={pending} type="text" value={question} onChange={e => setQuestion(e.target.value)} />
                    <button disabled={pending} onClick={submit} style={{marginLeft:'5%', width:'12%', border:'1px solid black', padding:'5px', borderRadius:'5px', color:pending?'gray':'white', backgroundColor:'#0e172f'}}>Ask</button>
                </div>
            </div>
        </div>
    );
}
