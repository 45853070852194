import {ChuckPresent} from './present.js';
import { useChuck } from './func.js';

export default function ChuckCompare () {

    const chuck = useChuck();
    const chuck2 = useChuck({
        compare: true, 
    });

    function onKeyDown (event) {
        chuck.onKeyDown(event);
        chuck2.onKeyDown(event);
    }
    
    function submit () {
        chuck.submit();
        chuck2.submit();
    }

    function setQuestion (str) {
        chuck.setQuestion(str);
        chuck2.setQuestion(str);
    }

    return (
        <ChuckPresent
            messages={chuck.messages}
            pending={chuck.pending}
            messages2={chuck2.messages}
            pending2={chuck2.pending}
            question={chuck.question}
            setQuestion={setQuestion}
            submit={submit}
            onKeyDown={onKeyDown}
        />
    );

}