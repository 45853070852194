import IconPlus from "../assets/images/icon-plus.svg";

export default function AddBtn({ crud }) {
  return (
    <div>
      <a href="#" onClick={crud.goAdd} className="button top w-inline-block">
        <div className="text-block-2">New</div>
        <img
          src={IconPlus}
          loading="lazy"
          width="Auto"
          alt=""
          className="icon-end"
        />
      </a>
      {false && (
        <a href="#" className="button top disable" style={{ marginLeft: "2%" }}>
          Disable
        </a>
      )}
    </div>
  );
}
