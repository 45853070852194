import {useEffect} from 'react';
import {fcaps} from '../modules/str/index.js';
import {keyListener} from '../App.js';

export default function Delete ({del, close, type, crud}) {

    useEffect(() => keyListener({ 
        'Enter': () => {
            del();
        },
    }), [crud, del]);

    return (
        <div id="modal" className="modal">
            <div id="modal-content" className="modal-content">
                <span onClick={close} className="close-button">×</span>
                <h3 className="heading-2">Delete {fcaps(type)}</h3>
                <div>Are you sure you want to delete this {type}?</div>
                <br />
                <a href="#" onClick={del} className="button top w-inline-block">
                    <div className="text-block-2">Yes</div>
                </a>
                <br />
                <a href="#" onClick={close} className="button top w-inline-block">
                    <div className="text-block-2">No</div>
                </a>
            </div>
        </div>
    );
}