import {useEffect} from 'react';
import AddEdit from './addEdit.js';
import assert from '../../helpers/assert.js';
import {ListView} from "../../components/list.js";
import {keyListener} from '../../App.js';

export default function List ({user, crud, list, setModel}) {

    useEffect(() => keyListener({
        'Escape': () => crud.close && crud.close(),
    }), [crud]);

    // assertions
    let rows = crud.list.rows(obj=>obj);
    assert(rows && rows.length, 'timeline has rows');
    if (rows && rows.length) assert(rows[0].organization, 'timeline has site');

    return (
        <>
        <ListView
            crud={crud} 
            user={user} 
            list={list} 
            setModel={setModel} 
            AddEdit={AddEdit}
            type="timeline"
            title="Timelines"
            addTitle="Add New Timeline"
            editTitle="Edit Timeline"
            fields={[
                { field:'name', friendly:'Name', showOnMobile:true },
                { field:'organization', friendly:'Site', showOnMobile:true },
                { field:'when_inserted', friendly:'Created', showOnMobile:false },
                { field:'when_updated', friendly:'Modified', showOnMobile:false },
            ]}
        />
        <button style={{padding:'10px', backgroundColor:'black', color:'white'}} onClick={() => setModel('timeline_item')}>Timeline Items</button>
        </>
    );
}