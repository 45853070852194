import reseticon from '../../assets/images/icon-reset.svg';
import advanceicon from '../../assets/images/icon-advance.svg';
import {Confirm} from '../../components/confirm.js';

export function PresenterControls ({user, session}) {
    return (
      user.user && user.user.user_type.name === "presenter" && (
        <>

        <div className="space-between title">

          <div className="header-left">

            <a
              className="menu-link present w-inline-block"
              onClick={session.rewind} 
            >
              <img
                src={advanceicon}
                loading="lazy"
                width="14"
                alt="Rewind"
                className="menu-icon"
              />
              <div className="navlink">Rewind&nbsp;</div>
            </a>

            <a
              className="menu-link present w-inline-block"
              onClick={session.advance}
            >
              <div className="navlink">Advance&nbsp;</div>
              <img
                src={advanceicon}
                loading="lazy"
                width="14"
                alt="Advance"
                className="menu-icon"
              />
            </a>

            <a
              className="menu-link present w-inline-block"
              onClick={session.reset.click}
            >
              <div className="navlink">Reset&nbsp;</div>
              <img
                src={reseticon}
                loading="lazy"
                width="14"
                alt="Reset"
                className="menu-icon"
              />
            </a>

            <a
              className="menu-link present w-inline-block"
              onClick={session.resetAndClear.click} 
            >
              <div className="navlink">Reset and Clear User Posts/Comments&nbsp;</div>
              <img
                src={reseticon}
                loading="lazy"
                width="14"
                alt="Reset"
                className="menu-icon"
              />
            </a>

          </div>
        </div>
        { session.reset.isConfirming && <Confirm yes={session.reset.confirm} no={session.reset.cancel} /> }
        { session.resetAndClear.isConfirming && <Confirm yes={session.resetAndClear.confirm} no={session.resetAndClear.cancel} /> }
        </>
      )
    );
}