import * as config from '../config-fe/index.js';

let token = null;

export function clearToken () {
    token = null;
}

export async function get (url, returnHeaders = false, json = true)
{
    let result = await doFetch(url, 'GET', null, returnHeaders, json);
    return (result);
}

export async function post (url, body, returnHeaders = false, json = true)
{
    let result = await doFetch(url, 'POST', body, returnHeaders, json);
    return (result);
}

export async function put (url, body, returnHeaders = false, json = true)
{
    let result = await doFetch(url, 'PUT', body, returnHeaders, json);
    return (result);
}

export async function doDelete (url, returnHeaders = false, json = true)
{
    let result = await doFetch(url, 'DELETE', null, returnHeaders, json);
    return (result);
}

async function doFetch (url, method, data, returnHeaders, json = true)
{
    if (!url.includes('http'))
    {
        let base = config.get('fetch.base');
        url = base + url;
    }
    return (new Promise (async (resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json',
        };
        if (!json) headers = {};
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        try {
            let body = null;
            if (data) {
                body = json ? JSON.stringify(data) : data;
            }
            let res = await fetch (url, {
                method,
                body,
                headers: new Headers (headers),
            });
            let responseHeaders = {};
            if (returnHeaders) {
                responseHeaders.totalPages = Number(res.headers.get('Total-Pages'));
                responseHeaders.totalCount = Number(res.headers.get('Total-Count'));
                responseHeaders.totalFilteredCount = Number(res.headers.get('Total-Filtered-Count'));
                responseHeaders.startIndex = Number(res.headers.get('Start-Index'));
                responseHeaders.endIndex = Number(res.headers.get('End-Index'));
                responseHeaders.currPage = Number(res.headers.get('Current-Page'));
                responseHeaders.perPage = Number(res.headers.get('Per-Page'));
                responseHeaders.search = res.headers.get('Search');
                responseHeaders.sortCol = res.headers.get('Sort-Column');
                responseHeaders.sortOrder = res.headers.get('Sort-Order');
            }
            res = await res.json();
            if (res.token) {
                token = res.token;
            }
            if (returnHeaders) {
                resolve ({
                    data: res,
                    headers: responseHeaders,
                });
            }
            else {
                resolve(res);
            }
        }
        catch (error) {
            reject (error);
        }
    }));
}
