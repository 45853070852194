export function get (str, envObj, configObj, defaultValue)
{
   // break up the request pieces
   let requestPieces = str.split('.');

   // start with list of all possible environment vars
   let envPieces = [];
   if (envObj.env) envPieces.push(envObj.env);
   if (envObj.role) envPieces.push(envObj.role);
   if (envObj.which) envPieces.push(envObj.which);

   // find first matching config entry
   let currConfig = configObj[requestPieces[0]];
   //let currConfigStr = requestPieces[0]; // ega was causing lint error
   requestPieces.splice(0, 1);
   if (!currConfig)
   {
     return (undefined);
   }

   // find matches deeper and deeper until we can go no more
   for (;;)
   {
      let foundMatch = false;

      // check for match with request pieces
      if (requestPieces.length)
      {
         if (requestPieces[0] in currConfig)
         {
            //currConfigStr += '.' + requestPieces[0]; // ega was causing lint error
            currConfig = currConfig[requestPieces[0]];
            requestPieces.splice(0, 1);
            foundMatch = true;
         }
      }

      // check for match with any environment pieces
      for (let i=0; i<envPieces.length; i++)
      {
         let envPiece = envPieces[i];
         if (typeof (currConfig) === 'object' && envPiece in currConfig)
         {
            //currConfigStr += '.' + envPiece; // ega was causing lint error
            currConfig = currConfig[envPiece];
            envPieces.splice(i, 1);
            foundMatch = true;
            break;
         }
      }

      // break out of loop if we found neither
      if (!foundMatch)
      {
         break;
      }
   }

   if (requestPieces.length)
   {
      return (defaultValue ? defaultValue : undefined);
   }

   return (currConfig);
}
