/* eslint-disable react-hooks/rules-of-hooks */

import {useState, useEffect} from 'react';

export function useForm (options, fieldsParam) {
    
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const [fields, setFields] = useState(setup(fieldsParam));

    //console.log('old man shirt',fields.auto_generate_posts); // ega

    useEffect(() => {
        if (!Object.keys(fields).length) {
            setFields(setup(fieldsParam));
        }
    }, [fieldsParam]);

    function setup (fields) {
        let res = {};
        fields.forEach(field => {
            field.value = field.initialValue;
            field.set = event => set(field.id, event);
            res[field.id] = field;
        });
        //console.log('res',res);// ega
        return (res);
    }

    function set (id, eventOrStr) {
        let value = eventOrStr.target ? eventOrStr.target.value : eventOrStr;
        //value = (value === 'on') ? true : false; // ega only do this if chechbkox
        console.log('set',id,value);// ega
        setFields(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                value,
            },
        }));
    }

    function compile () {
        let res = {};
        let ids = Object.keys(fields);
        ids.forEach(id => {
            res[id] = fields[id].value;
        });
        return (res);
    }

    function resetFields (fieldsParam) {
        setFields(setup(fieldsParam));
    }

    async function submit () {
        if (validate()) {
            if (options.submit) {
                let values = compile();
                setError(null);
                setPending(true);
                await options.submit(values);
                setPending(false);
            }
        }
    }

    function validate () { // ega
        return (true);
    }

    return ({
        fields,
        resetFields,
        error,
        setError,
        pending,
        setPending,
        submit,
    });

}