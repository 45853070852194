export function isAlpha (ch) {
	return (/^[A-Z]$/i.test(ch));
}

export function isLetter (str) {
	return str.length === 1 && str.match(/[a-z]/i);
}

export function fcaps (string) {
  let words = string.split (' ');
  words = words.map (word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  return (words.join (' '));
}

export function endsWith (haystack, needle) {
  return (haystack.substr(haystack.length - needle.length) === needle);
}

export function until (str, end) {
  let i = str.indexOf(end);
  if (i === -1) {
    return (null);
  }
  return (str.substring(0, i));
}

export function after (str, start) { 
  let i = str.indexOf(start);
  if (i === -1) {
    return (null);
  }
  return (str.substring(i + start.length));
}

export function from (str, start) {
  let i = str.indexOf(start);
  if (i === -1) {
    return (null);
  }
  return (str.substring(i));
}

export function isLegalFilename (ch) {
  if (ch >= 'a' && ch <= 'z') {
    return (true);
  }
  if (ch >= 'A' && ch <= 'Z') {
    return (true);
  }
  if (ch >= '0' && ch <= '9') {
    return (true);
  }
  if (ch === ' ') {
    return (true);
  }
  return (false);
}

export function between (str, start, end) {
  let i = str.indexOf(start);
  if (i === -1) {
    return (null);
  }
  i += start.length;
  str = str.substring(i);
  i = str.indexOf(end);
  if (i === -1) {
    return (null);
  }
  str = str.substring(0, i);
  return (str);
}

function isAlphaNumeric(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
      return false;
    }
  }
  return true;
};

export function includesWholeWord (str, match) {
  let i = str.indexOf(match);
  if (i === -1) {
    return (false);
  }
  if (i !== 0 && isAlphaNumeric(str[i-1])) {
    return (false);
  }
  if (str.length > i + match.length && isAlphaNumeric(str[i + match.length])) {
    return (false);
  }
  return (true);
}

export function mostRecentIndexOf (str, i, match) {
  for (; i >= 0; i--) {
    if (str.substring(i, i+match.length) === match) {
      return (i);
    }
  }
  return (-1);
}