export function ParticipantList ({session, user}) {
    return (
            <>
              <div>
                <strong>
                  session: {session.session.id} <br />
                  position:{" "}
                  {session.posts && session.posts.length && session.pos}
                </strong>
              </div>
              <br />
    
              <strong>participants:</strong>
              <br />
              {session.participants.map((p, i) => (
                <div key={i}>
                  {p.username} - {p.type}
                  {p.username === user.user.username && <span>&nbsp;(you)</span>}
                </div>
              ))}
              <br />
    
              {!session.presenterJoined && (
                <div
                  style={{
                    color: "white",
                    padding: "10px",
                    backgroundColor: "red",
                    borderRadius:'5px',
                  }}
                >
                  No presenter is currently joined.
                </div>
              )}
              {!session.viewerJoined && (
                <div
                  style={{
                    color: "white",
                    padding: "10px",
                    backgroundColor: "red",
                    borderRadius:'5px',
                  }}
                >
                  No viewers are currently joined.
                </div>
              )}
            </>
    );
}