import {useEffect, useState} from 'react';
import {get} from '../fetch-fe/index.js';
import {modelToRoute} from './helpers.js';
import {useList} from './list.js';
import {useAdd} from './add.js';
import {useEdit} from './edit.js';
import {useDelete} from './delete.js';
import assert from './assert.js';

// ega is there a way to avoid calling useAdd, useEdit and useDelete when not all screens are active?

export function useCrud (modelParam, options) {

    const [model, setModel] = useState(modelParam ? modelParam : 'user'); // ega this is app specific
    const [struct, setStruct] = useState([]);
    const [mode, setMode] = useState((options && options.mode) ? options.mode : 'list');
    const [target, setTarget] = useState(null);
    const list = useList({model, struct, goEdit, goDel});
    const add = useAdd({model, done:resetModeAndLoad, struct});
    const edit = useEdit({model, target, done:resetModeAndLoad, struct});
    const del = useDelete({model, target, done:resetModeAndLoad});

    useEffect(() => {
        setNewModel(model);
    }, []);

    async function setNewModel (name) {

        // update state
        setModel(name);
        if (!options || !options.mode || options.mode === 'list') {
            resetMode();            
        }
        list.search.reset();

        // load struct and data
        await loadStruct(name);
        await list.load(name);
    }

    async function loadStruct (name) {
        // get all the fields for this model
        setStruct([]);
        let res = await get('/' + modelToRoute(name) + '/struct');
        setStruct(res);
    }

    function resetModeAndLoad () {
        resetMode();
        list.load();
    }

    function resetMode () {
        setMode('list');
    }

    function goAdd () {
        setMode('add');
    }

    function goEdit (obj) {
        setMode('edit');
        setTarget(obj);
    }

    function goDel (obj) {
        setMode('delete');
        setTarget(obj);
    }

    let ret = {

        struct, // ega this is temporary, just for now, so i can figure out when there is a mismatch between struct and data
        
        // model
        model,
        setModel: setNewModel,

        // list page
        list: {
            rows: list.rows,
            pagination: list.pagination,
            search: list.search,
            sort: list.sort,
        },

        // general modal stuff
        close: (mode === 'list') ? undefined : resetMode,

        // add modal
        add: (mode === 'add') ? add : undefined, 
        goAdd: (mode === 'list') ? goAdd : undefined, 

        // edit modal
        edit: (mode === 'edit') ? edit : undefined, 
        goEdit: (mode === 'list') ? goEdit : undefined, 

        // delete modal
        del: (mode === 'delete') ? del : undefined,
        goDel: (mode === 'list') ? goDel : undefined,

    };

    // assertions
    let rows = ret.list.rows(obj=>obj);
    if (rows && rows.length) assert(rows[0].id, 'crud has list');
    if (ret.model !== 'user' && ret.struct && ret.struct.length && ret.model !== ret.struct[0].model) console.log('mismatch in damn model', ret.struct);

    return (ret);
}