import { AddEditDlg } from "../../components/addEditDlg";

export default function AddEdit ({close, form, title}) {
    return (
        <AddEditDlg 
            close={close} 
            form={form} 
            title={title} 
            pwdMsg={null} 
            fields={[ 
                { field:'name', friendly:'Name' },
                { field:'url_logo', friendly:'Logo' },
            ]}
        />
    );
}
