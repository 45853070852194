import {useEffect, useState, useRef} from 'react';
import * as stream from '../../modules/stream-fe/index.js';
import {get, post} from '../../modules/fetch-fe/index.js';
import * as config from '../../modules/config-fe/index.js';
import {useCrud} from '../../modules/crud-ui/index.js';
import moment from 'moment';

function useInterval(callback, duration) {
    const savedCallback = useRef();
  
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    useEffect(() => {

      function tick() {
        if (savedCallback.current) {
          savedCallback.current();
        }
      }
  
      const intervalId = setInterval(tick, duration * 1000);
  
      return () => clearInterval(intervalId);

    }, [duration]);
}  

function gridify (array, columns) {
    let rows = [];
    for (let i=array.length-1; i>=0; i--) {
        let element = array[i];
        if (!rows.length || rows[rows.length-1].length === columns) {
            rows.push([]);
        }
        rows[rows.length-1].push(element);
    }
    return (rows);
}

function groupify (posts) {

    // get distinct list of network names
    let networkNames = [];
    for (let i=0; i<posts.length; i++) {
        let post = posts[i];
        if (!networkNames.includes(post.name)) {
            networkNames.push(post.name);
        }
    }

    // create empty columns one for each network
    let networks = networkNames.map(() => []);

    // add posts to each column
    let maxPosts = 0;
    for (let i=0; i<posts.length; i++) {
        let post = posts[i];
        let networkNum = networkNames.findIndex(n => n === post.name);
        networks[networkNum].push(post);
        maxPosts = (networks[networkNum].length > maxPosts) ? networks[networkNum].length : maxPosts;
    }

    // take last 5 in each column
    for (let i=0; i<networks.length; i++) {
        networks[i].splice(0, networks[i].length - 5); 
        networks[i].reverse();
    }

    // fill in blank spaces
    for (let i=0; i<networkNames.length; i++) {
        while (networks[i].length < maxPosts) {
            networks[i].push(null);
        }
    }

    // rotate this array by 90 degrees
    let rows = [];
    for (let i=0; i<maxPosts; i++) {
        rows.push([]);
        for (let j=0; j<networkNames.length; j++) {
            rows[rows.length - 1].push(networks[j][i]);
        }
    }

    return (rows);
}

function useCommentExpand () {

    const [expandedComments, setExpandedComments] = useState([]);

    function isExpanded (id) {
        return (expandedComments.includes(id));
    }

    function toggleComment (id) {
        if (isExpanded(id)) {
            setExpandedComments(expandedComments.filter(c => c !== id));
        }
        else {
            setExpandedComments([...expandedComments, id]);
        }
    }

    function expand (id) { 
        let list = expandedComments.filter(c => c !== id);
        list = [...list, id];
        setExpandedComments(list);
    }

    function collapse (id) { 
        setExpandedComments(expandedComments.filter(c => c !== id));
    }

    function collapseAll () {
        setExpandedComments([]);
    }

    return ({
        isExpanded,
        toggle: toggleComment,
        collapseAll,
        expand,
        collapse,
    });

}

export function useSession ({user}) { 

    const [session, setSession] = useState(null);
    const [isRestoring, setIsRestoring] = useState(false);
    const [matchingSessions, setMatchingSessions] = useState(null);
    const [posts, setPosts] = useState([]);
    const [pos, setPos] = useState(0);
    const [activeStream, setActiveStream] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [video, setVideo] = useState(null);
    const [grid, setGrid] = useState([]);
    const [currComment, setCurrComment] = useState('');
    const [postAddingCommentTo, setPostAddingCommentTo] = useState(null);
    const [commentPending, setCommentPending] = useState(false);
    const [isAddingPost, setIsAddingPost] = useState(false);
    const [isConfirmingReset, setIsConfirmingReset] = useState(false);
    const [isConfirmingResetAndClear, setIsConfirmingResetAndClear] = useState(false);
    const crud = useCrud('post', {mode:'add'});
    const commentExpand = useCommentExpand();
    const [lastPingTimestamp, setLastPingTimestamp] = useState(null);
    const [connectedStatus, setConnectedStatus] = useState(false);
    useInterval(checkPing, 5); 

    //// ping

    //ega don't make it keep doing this, it needs to trigger once, then try to reconnect
    function checkPing () { 
        if (lastPingTimestamp) {
            const now = moment();
            const diff = now.diff(lastPingTimestamp, 'seconds'); 
            if (diff >= 5 && !isRestoring) {
                //setIsRestoring(true);//ega 
                console.log('check 1');//ega
                setConnectedStatus(false);
                console.log('check 2');//ega
                stream.disconnectAll();
                console.log('check 3');//ega
                initStream();        
                console.log('check 4');//ega
            }
            else {
                setConnectedStatus(true);
            }
        }
    }

    //// posts

    useEffect(() => { // setup posts to be displayed as grid or groups
        if (session && posts && posts.length) {
            setGrid((session.timeline_type === 'grouped') ? groupify(postSubset(posts)) : gridify(postSubset(posts), 3));
        }
    }, [session, posts]);

    function postSubset () {

        let max = 9;

        if (!posts || !posts.length) {
            return ([]);
        }

        if (session.timeline_type === 'grouped') {
            return (posts.slice(0, pos+1));
        }

        if (pos < max) {
            return (posts.slice(0, pos+1));
        }
        else {
            return (posts.slice(pos-(max-1), pos+1));
        }

    }
    
    //// live posting

    useEffect(() => { // keep the post crud in "add" mode
        if (crud.goAdd) {
            crud.goAdd();
        }
    }, [crud.goAdd, crud]);

    function beginPost () {
        setIsAddingPost(true);
        if (crud && crud.add && crud.add.form && crud.add.form.fields && crud.add.form.fields.id_user) {
            crud.add.form.fields.title.set('');
            crud.add.form.fields.content.set('');
            crud.add.form.fields.id_user.set(user.user.id);
            crud.add.form.fields.live.set(true);    
            crud.add.form.fields.active.set(true);
        }
    }

    function cancelPost () {
        setIsAddingPost(false);
    }

    function submitPost () { 
        setIsAddingPost(false);
    }

    //// live commenting

    async function saveComment () {
        setCommentPending(true);
        await post('/comment', {
            id_post: postAddingCommentTo,
            id_user: user.user.id,
            comment: currComment,
            active: true,
        });
        commentExpand.expand(postAddingCommentTo);
        setCommentPending(false);
        setPostAddingCommentTo(null);
        setCurrComment('');
    }

    function expandNewComments (newPosts, oldPosts) {
        for (let i=0; i<newPosts.length; i++) {
            let newPost = newPosts[i];
            let oldPost = oldPosts.find(p => p.id === newPost.id);
            if (newPost && oldPost && newPost.comments && oldPost.comments) {
                if (newPost.comments.length !== oldPost.comments.length) {
                    commentExpand.expand(newPost.id);
                }
            }
        }
    }

    //// streaming

    useEffect(() => { // set up stream once session is joined
        initStream();
        return (() => {
            stream.disconnectAll();
        });
    }, [session]);
    
    function receiveMsg (msg) {

        let collapseAll = false;
        let parsedMsg = JSON.parse(msg);

        if (parsedMsg.ping) {
            setLastPingTimestamp(moment());
            setConnectedStatus(true); 
            return;
        }

        setPosts(prevPosts => {
            expandNewComments(parsedMsg.posts, prevPosts);
            return (parsedMsg.posts);
        });

        if (parsedMsg.current_position !== pos) {
            collapseAll = true;
        }
        setPos(parsedMsg.current_position);

        setParticipants(parsedMsg.participants);

        if (collapseAll) {
            commentExpand.collapseAll();
        }
    }

    function didConnect (stream) {
        setActiveStream(stream);
    }

    function initStream () {
        if (user.user && session && !activeStream) {
            let url = config.get('ws.base') + '/stream?token=' + user.user.token + '&session=' + session.id;
            let res = stream.init({
                url,
                msg: receiveMsg,  
                connect: () => setIsRestoring(false),
                disconnect: () => setIsRestoring(true),
                didConnect,
            });
            setActiveStream(res);
        }
    }

    //// sessions

    useEffect(() => { // set up session picker
        getMatchingSessions();
    }, []);

    async function getMatchingSessions () {
        try {
            // find sessions matching the current user
            let res = await get('/session/matching');

            // show user a list of possible sessions
            setMatchingSessions(res);

            // if only one matching, go ahead and join the session
            if (res.length === 1) {
                setSession(res[0]);
            }
        }
        catch (error) {
            // do nothing
        }
    }

    //// presenter controls

    async function advance (e) {
        e.stopPropagation(); //ega not working
        e.preventDefault();
        activeStream.send(JSON.stringify({
            pos: pos + 1,
        }));
    }

    async function rewind (e) {
        e.stopPropagation(); //ega not working
        e.preventDefault();
        if (pos > 0) {
            activeStream.send(JSON.stringify({
                pos: pos - 1,
            }));    
        }
    }

    async function reset () {
        setIsConfirmingReset(false);
        activeStream.send(JSON.stringify({
            pos: 0,
        }));
    }

    async function resetAndClear () {
        setIsConfirmingResetAndClear(false);
        activeStream.send(JSON.stringify({
            pos: 0,
            clearPostsComments: true,
        }));
    }

    //// logout

    function logout () {
        stream.disconnectAll();
        user.logout();
    }

    return ({ 

        // commenting
        comment: {
            value: currComment,
            setValue: e => setCurrComment(e.target.value), 
            post: {
                id: postAddingCommentTo,
                start: id => { 
                    setCurrComment('');
                    setPostAddingCommentTo(id);
                },
                cancel: () => setPostAddingCommentTo(null),
            },
            submit: saveComment, 
            pending: commentPending,
            expand: commentExpand, 
        },

        // posting 
        post: {
            isAddingPost,
            start: isAddingPost ? null : beginPost,
            cancel: isAddingPost ? cancelPost : null,
            submit: isAddingPost ? submitPost : null,
            form: (crud && crud.add) ? crud.add.form : null,
        },

        posts: postSubset(),
        allPosts: posts,
        grid,

        pos,
        advance,
        rewind,
        reset: {
            click: isConfirmingReset ? null : (() => setIsConfirmingReset(true)),
            isConfirming: isConfirmingReset,
            confirm: isConfirmingReset ? reset : null,
            cancel: isConfirmingReset ? (() => setIsConfirmingReset(false)) : null,
        },
        resetAndClear: { 
            click: isConfirmingResetAndClear ? null : (() => setIsConfirmingResetAndClear(true)),
            isConfirming: isConfirmingResetAndClear,
            confirm: isConfirmingResetAndClear ? resetAndClear : null,
            cancel: isConfirmingResetAndClear ? (() => setIsConfirmingResetAndClear(false)) : null,
        },

        connectedStatus,
        isRestoring,
        matchingSessions,
        session,
        setSession,
        participants,
        showParticipantList: config.get('session.showParticipantList'),
        presenterJoined: participants.some(p => p.type === 'presenter'),
        viewerJoined: participants.some(p => p.type === 'viewer'),
        logout,

        video: {
            isPlaying: (video !== null),
            url: video, 
            play: video === null ? setVideo : null,
            stop: () => setVideo(null),
        }
    });

}
