import { AddEditDlg } from "../../components/addEditDlg";

export default function AddEdit ({close, form, title}) {
    return ( 
        <AddEditDlg 
            close={close} 
            form={form} 
            title={title} 
            pwdMsg={null} 
            fields={[ 
                { field:'id_timeline', friendly:'Timeline' },
                { field:'id_post', friendly:'Post' },
                { field:'position', friendly:'Position' },
            ]}
        />
    );
}
