import {doDelete} from '../fetch-fe/index.js';
import {modelToRoute} from './helpers.js';

export function useDelete({ done, model, target }) {

  async function del () {
    await doDelete('/' + modelToRoute(model) + '/' + target.id);
    done();
  }

  return ({
    del,
    target,
  });

}
