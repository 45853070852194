//import * as config from '../config-fe/index.js';
import {post} from '../fetch-fe/index.js';
import Cookies from 'js-cookie';
import {useForm} from '../form-ui/index.js';

export function useLogin({didLogin, cancel}) {

  //let allowPasswordReset = config.get('user.allowPasswordReset', true);
  //let allowRegistration = config.get('user.allowRegistration', true);
  const form = useForm({ submit: login }, [
      { input:'text', id:'username', label:'Username', validation:{minLength:5, required:true, prompt:'at least 5 characters', error:'Invalid username'} },
      { input:'password', id:'password', label:'Password', validation:{required:true, error:'Password is required.'} },
      { input:'checkbox', initialValue:true, id:'remember', label:'Remember me', validation:{minLength:5, required:false}},
      //{ input:'submit', label:'LOGIN'},
      //{ input:'cancel', label:'CANCEL', onClick: cancel},
      // ega !allowPasswordReset ? null : { link:() => alert('clicked forgot'), label:'Forgot your password?' }, // ega implement this
      // ega !allowRegistration ? null : { link:() => alert('clicked create'), label:'Create an account', right:true }, // ega implement this
  ]);

  async function login ({username, password, remember}) {
    try {
      let user = await post('/user/auth', {username, password});
      Cookies.set('token', remember ? user.token : '');
      didLogin (user);
    }
    catch (error) {
      form.setError('Invalid login. Please try again.');
    }
  }

  return (form);

}