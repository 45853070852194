export function FullScreenDiv({ onClick, children }) {
  const cstyle = {
    position: 'relative',
    opacity: 1,
  };
  const style = {
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: 'black',
    opacity: 0.5,
  };

  function click(e) {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) {
      onClick();      
    }
  }

  return (
    <>
      <div style={style} onClick={click} />
      <div style={cstyle}>
        {children}
      </div>
    </>
  );
}