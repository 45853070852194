// ega only console.log if dbg on in config

let socket = null;

export function init (options) {

    socket = new WebSocket(options.url);
  
    socket.onopen = function(event) {
        if (options.connect) {
          options.connect();
        }
        //console.log('dbg WebSocket connection established');
    };
    
    socket.onmessage = function(event) {
      if (options.msg) {
        options.msg(event.data);
      }
    };
        
    socket.onclose = function(event) {
      if (!event.wasClean) {
        if (options.disconnect) {
          options.disconnect();
        }
        setTimeout(() => {
          let res = init(options);
          if (res && options.didConnect) {
            options.didConnect(res);
          }
        }, 500);
      }
    };  
    return (socket);
    
  }

  export function disconnectAll () {
    if (socket) {
      socket.close();
      socket = null;
    }
    // ega make sure this isn't trying to re-connect after we closed it
  }