import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App.js';

// set up config
import * as ConfigFE from './modules/config-fe/index.js';
import * as config from './config/config.js';
import * as env from './config/env.js';
ConfigFE.use(config, env);

const strictMode = false; 

const root = ReactDOM.createRoot(document.getElementById('root'));
if (strictMode) {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );  
}
else {
  root.render(<App />);
}