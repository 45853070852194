import {ChuckPresent} from './present.js';
import { useChuck } from './func.js';

export default function Chuck () {
    const chuck = useChuck();
    return (
        <ChuckPresent
            messages={chuck.messages}
            pending={chuck.pending}
            question={chuck.question}
            setQuestion={chuck.setQuestion}
            submit={chuck.submit}
            onKeyDown={chuck.onKeyDown}
        />
    );
}