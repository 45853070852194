export default function Search({ list }) {
  return (
    <input
      type="text"
      placeholder="Search"
      size="30"
      value={list.search.val}
      onChange={e => list.search.set(e.target.value)}
    />
  );
}
