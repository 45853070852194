import { useEffect } from "react";
import AddEdit from "./addEdit.js";
import assert from "../../helpers/assert.js";
import { ListView } from "../../components/list.js";
import { keyListener } from "../../App.js";

export default function List({ user, crud, list, setModel }) {
  useEffect(
    () =>
      keyListener({
        Escape: () => crud.close && crud.close()
      }),
    [crud]
  );

  // assertions
  let rows = crud.list.rows(obj => obj);
  if (rows && rows.length) assert(rows[0].name, "site has name");

  return (
    <ListView
      crud={crud}
      user={user}
      list={list}
      setModel={setModel}
      AddEdit={AddEdit}
      type="Site"
      title="Sites"
      addTitle="Add New Site"
      editTitle="Edit Site"
      fields={[
        { field: "name", friendly: "Name", showOnMobile: true },
        { field: "url_logo", friendly: "Logo", imgref:true, showOnMobile: true },
        { field: "when_inserted", friendly: "Created", showOnMobile: false },
        { field: "when_updated", friendly: "Modified", showOnMobile: false }
      ]}
    />
  );
}
