import { AddEditDlg } from "../../components/addEditDlg";

export default function AddEdit ({close, form, title, pwdMsg}) {
    return (
        <AddEditDlg 
            close={close} 
            form={form} 
            title={title} 
            pwdMsg={pwdMsg} 
            fields={[ 
                { field:'username', friendly:'Username' },
                { field:'email', friendly:'Email' },
                { field:'password', friendly:'Password' },
                { field:'id_user_type', friendly:'Type' },
            ]}
        />
    );
}
