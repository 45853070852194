export function AddComment({ value, set, add, cancel, pending }) { 
  return (
    <div>
      {!pending && (
        <div>
          <input type="text" value={value} onChange={set} />
        </div>
      )}
      <button disabled={pending} onClick={add}>
        {pending ? "Saving..." : "Add"}
      </button>
      <button disabled={pending} onClick={cancel}>
        Cancel
      </button>
    </div>
  );
}
