import {Restoring} from './restoring.js';
import {PickSession} from './pickSession.js';
import {Session} from './session.js';

export function SessionPresent({ user, session }) {

  if (session.isRestoring) {
    return (<Restoring />);
  }

  if (session.session) {
    return (<Session user={user} session={session} />);
  }

  if (session.matchingSessions) {
    return (<PickSession session={session} />);
  }

}
