import {Cards} from './cards.js';

export function Posts ({user, session}) {

    if (!session.presenterJoined) return (null);

    if (user.user.user_type.name === 'presenter') {
      return (
        <div style={{display:'grid', gridTemplateColumns:'1fr 5fr', gap:'10px'}}>
          <div style={{backgroundColor:'#e5e6eb'}}>
            <Cards sideView={true} session={session} grid={session.grid} video={session.video} allPosts={session.allPosts} />
          </div>
          <Cards sideView={false} session={session} grid={session.grid} video={session.video} allPosts={session.allPosts} />
        </div>
      )
    }

    return (<Cards session={session} grid={session.grid} video={session.video} allPosts={session.allPosts} />);
}