import {useEffect} from 'react';
import assert from '../../helpers/assert.js';
import {ListView} from "../../components/list.js";
import {keyListener} from '../../App.js';
import AddEdit from './addEdit.js';

export default function List ({user, crud, list, setModel}) {

    useEffect(() => keyListener({ 
        'Escape': () => {
            crud.close && crud.close();
        },
    }), [crud]);

    // assertions
    let rows = crud.list.rows();
    if (rows && rows.length) assert(rows[0].username, 'user has username');

    return (
        <ListView
            crud={crud} 
            user={user} 
            list={list} 
            setModel={setModel} 
            AddEdit={AddEdit}
            type="user"
            title="Users"
            addTitle="Add New User"
            editTitle="Edit User"
            pwdMsg="(Note: Only edit this field if you wish to change the existing password.  Otherwise, leave it empty.)"
            fields={[
                { field:'username', friendly:'Username', showOnMobile:true },
                { field:'email', friendly:'Email', showOnMobile:true },
                { field:'status', friendly:'Status', showOnMobile:true },
                { field:'user_type', friendly:'Type', showOnMobile:true },
                { field:'when_inserted', friendly:'Created', showOnMobile:false },
                { field:'when_updated', friendly:'Modified', showOnMobile:false },
            ]}
        />
    );
}