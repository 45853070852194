//ega this can be split up a bit better between functional and presentational

function AddEditField ({form, field, pwdMsg}) {

    // handle logo
    if (field.field === 'url_logo') {
        return ( 
            <div className="rows p col">
                <label>{field.friendly}</label>
                <input type="file" id="uploaded_file" />
            </div>
        );
    }

    // handle images
    if (field.field === 'url_image') {
        return ( 
            <div className="rows p col">
                <label>{field.friendly}</label>
                <input type="file" id="uploaded_file" />
            </div>
        );
    }

    if (field.field === 'url_video') {
      return (
        <div className="rows p col">
          <label>{field.friendly}</label>
          <input
            value={form.fields[field.field].value}
            onChange={form.fields[field.field].set}
            type="text"
            className="text-field form"
            placeholder=""
          />
        </div>
      );
    }

    // drop-down input
    if (form.fields[field.field].options) {
        return (
            <div className="custom-select">
                <label>{field.friendly}</label>
                <select value={form.fields[field.field].value} onChange={form.fields[field.field].set}>
                    { form.fields[field.field].options.map((option, i) =>
                        <option key={i} value={option.id}>{option.name}</option>
                    )}
                </select>
            </div>
        );
    }

  // password input
  else if (field.field === "password") {
    return (
      <div className="rows p col">
        <label>Password</label>
        <span>{pwdMsg}</span>
        <input
          value={form.fields.password.value}
          onChange={form.fields.password.set}
          type="password"
          className="text-field form"
          placeholder=""
        />
      </div>
    );
  }

  else if (form.fields[field.field].input === "checkbox") {
    return (
      <div className="rows p col">
        <label>{field.friendly}</label>
        <input
          checked={form.fields[field.field].value}
          onChange={form.fields[field.field].set}
          type="checkbox"
          className="text-field form"
          placeholder=""
        />
      </div>
    );
  }

  // text input
  else {
    return (
      <div className="rows p col">
        <label>{field.friendly}</label>
        <input
          value={form.fields[field.field].value}
          onChange={form.fields[field.field].set}
          type="text"
          className="text-field form"
          placeholder=""
        />
      </div>
    );
  }
}

export function AddEditDlg({ close, form, title, pwdMsg, fields, onAdd }) {

  function click (e) { 
    return;//ega this func was causing a problem with the upload file picker buttons
    e.stopPropagation();
    e.preventDefault();
  }

  function submit () {
    form.submit();
    if (onAdd) {
      onAdd();
    }
  }

  return (
    <div id="modal" className="modal" onClick={click}>
      <div id="modal-content" className="modal-content">
        <span onClick={close} className="close-button">
          &times;
        </span>
        <h3 className="heading-2">{title}</h3>
        {fields.map((field, i) => (
          <AddEditField key={i} form={form} field={field} pwdMsg={pwdMsg} />
        ))}
        <a href="#" onClick={submit} className={'button top w-inline-block' + (form.pending ? ' disabled' : '')}>
          <div className="text-block-2">{form.pending ? 'Please wait...' : 'Save'}</div>
        </a>
      </div>
    </div>
  );

}
