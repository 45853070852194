import {useEffect} from 'react';
import logorev from "../../assets/images/logo-rev.png";
import iconlogo from "../../assets/images/logo.png";
import {keyListener} from '../../App.js';

export default function Login({ form }) {

    useEffect(() => keyListener({ 
        'Enter': () => {
          form.submit();
        },
    }), [form]);

  return (
    <section className="section grow">
      <div className="div-grow leftlogin">
        <div className="div-grow-top">
          <img
            src={logorev}
            loading="lazy"
            width="175"
            alt=""
            className="rev-logo"
          />
        </div>
        <div className="div-grow-bottom">
          <h3 className="white">RISK INTELLIGENCE</h3>
          <div>
            for Visionary Leaders<sup>™</sup>
          </div>
        </div>
      </div>
      <div className="div-grow login">
        <div className="div-grow-top">
          <img
            src={iconlogo}
            loading="lazy"
            width="145"
            alt=""
            className="mobile-logo"
          />
        </div>

        <div className="div-form">
          <h3 className="heading-2">Login to Your Account</h3>
          <input
            disabled={form.pending}
            type="text"
            className="text-field"
            style={{
              borderColor: form.fields.username.failedValidation
                ? "#ed4f9d"
                : "#e2e8f0"
            }}
            placeholder="Username or Email"
            required
            value={form.fields.username.value}
            onChange={form.fields.username.set}
          />
          {form.fields.username.failedValidation && (
            <div className="text-error">
              {form.fields.username.validation.error}
            </div>
          )}
          <input
            disabled={form.pending}
            type="password"
            className="text-field"
            placeholder="Password"
            value={form.fields.password.value}
            onChange={form.fields.password.set}
            style={{
              borderColor: form.fields.password.failedValidation
                ? "#ed4f9d"
                : "#e2e8f0"
            }}
          />
          {form.fields.password.failedValidation && (
            <div className="text-error">
              {form.fields.password.validation.error}
            </div>
          )}
          <button
            disabled={form.pending}
            onClick={form.submit}
            className="button w-button"
          >
            Login
          </button>
          {form.pending && <div className="text-error">Please wait...</div>}
          {form.error && <div className="text-error">{form.error}</div>}
        </div>
        <div className="div-grow-bottom"></div>
      </div>
    </section>
  );
}
