import {useState} from 'react';
import {post} from '../../modules/fetch-fe/index.js';
import * as config from '../../modules/config-fe/index.js';

export function useChuck () {
    const [thread, setThread] = useState(null);
    const [messages, setMessages] = useState([]);
    const [question, setQuestion] = useState('');
    const [pending, setPending] = useState(false);

    function goBottom () {
        setTimeout (() => {
            const div = document.getElementById('msgContainer');
            div.scrollTop = div.scrollHeight;
        }, 500);
    }

    function onKeyDown (event) {
        if (event.key === 'Enter') {
            submit();
        }
    }

    function addOutgoingQuestion (question) {
        setMessages(prev => [...prev, question]);
        goBottom();
    }

    function initUI () {
        setQuestion('');
        setPending(true);
    }

    async function ensureThread () {
        let thread_id;
        let askQuestion = true;
        let err = config.get('chuck.errors.serverProbs');
        if (thread) {
            thread_id = thread;
        }
        else {
            try {
                thread_id = await post('/chuck/thread'); 
                setThread(thread_id);    
            }
            catch (error) {
                setMessages(prev => [...prev, err]);
                goBottom();
                askQuestion = false;
            }
        }
        return ({thread_id, askQuestion});
    }
    
    function askTheQuestion (thread_id, askQuestion) {
        let err = config.get('chuck.errors.serverProbs');
        if (askQuestion) {
            let timerId;
            // ask the question
            try {
                let err = config.get('chuck.errors.stillThinking'); 
                timerId = setTimeout(() => {
                    timerId = null;
                    setMessages(prev => [...prev, err]);
                }, config.get('chuck.timeout')); 
                post('/chuck/ask', {thread_id, question})
                .then (responses => {
                    setPending(false);
                    setMessages(responses);
                    timerId && clearTimeout(timerId);
                    goBottom();        
                })
            }
            catch (error) {
                setPending(false);
                setMessages(prev => [...prev, err]);
                timerId && clearTimeout(timerId);
                goBottom();
            }
        }   
    }

    async function submit () {

        if (question.trim() === '') return;

        // init the ui
        initUI();

        // add this outgoing question
        addOutgoingQuestion(question);

        // make sure we have a thread
        let {thread_id, askQuestion} = await ensureThread();

        // ask the question
        askTheQuestion(thread_id, askQuestion);
        
    }

    return ({
        submit,
        messages, 
        pending,
        question,
        setQuestion,
        onKeyDown,
    });
}
