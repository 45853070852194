import {Header} from './header.js';
import {PresenterControls} from './presenterControls.js';
import {Posts} from './posts.js';
import {VideoPlayer} from './videoPlayer.js';
import {AddPost} from './addPost.js';
import { OrgLogo } from './orgLogo.js';
import {ParticipantList} from './participantList.js';

//ega make the red/green light a hook that returns functions to control it and a component to display
//ega   and you can give it options
//ega   and publish it

export function Session ({user, session}) {
  session.showParticipantList = false;//ega
  return (
    <section className="table-css col itemvh bg-bw" style={{userSelect:'none'}}>
      <Header session={session} />
      <OrgLogo logo={session.session.url_logo} />
      {   
          session.showParticipantList &&
          <div style={{border:'1px solid black', padding:'10px', borderRadius:'5px', margin:'10px', width:'50%', backgroundColor:'lightgray'}}>
            <div style={{backgroundColor:session.connectedStatus ? 'green' : 'red', marginBottom:'10px', marginRight:'10px', width:'20px', height:'20px', borderRadius:'10px'}} />
            <strong>timeline type: {session.session.timeline_type} </strong>
            <ParticipantList session={session} user={user} />
            <br />
          </div>
      }
      <PresenterControls session={session} user={user} />
      <AddPost session={session} />
      <Posts user={user} session={session} />
      <VideoPlayer user={user} session={session} />
    </section>
  );
}