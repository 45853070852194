export default function PaginationHeader({ pagination }) {
  return (
    <div className="pagination">
      Per page: &nbsp;
      <select value={pagination.perPage} onChange={pagination.setPerPage}>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="250">250</option>
      </select>
    </div>
  );
}
