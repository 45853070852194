module.exports = {
    session: {
        showParticipantList: {
            local: true,
            test: false,
            prod: false,
        },
    },
    chuck: {
        errors: {
            serverProbs: 'CHUCK is experiencing server difficulties at the moment.  Please wait and try your question again.',
            stillThinking: 'Chuck is still thinking...'
        },
        errors2: {
            serverProbs: 'ChatGPT is experiencing server difficulties at the moment.  Please wait and try your question again.',
            stillThinking: 'ChatGPT is still thinking...'
        },
        timeout: 15000
    },
    fetch: {
        base: {
            local: 'http://localhost',
            test: 'https://testapi.agilion.com',
            prod: 'https://api.agilion.com',
        },
    },
    ws: {
        base: {
            local: 'ws://localhost',
            test: 'wss://testapi.agilion.com',
            prod: 'wss://api.agilion.com',
        },
    },
    user: {
        goLoginWhenLoggedOut: true,
        allowPasswordReset: true,
        allowRegistration: true,
    },
    elements: {
        defaultValidationPrompt: 'You must get this field right.',
        defaultValidationError: 'Validation error.',
    },
};
