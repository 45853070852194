import {useState, useEffect} from 'react'; 
import {get} from '../fetch-fe/index.js';
import moment from 'moment';
import {modelToRoute} from './helpers.js';

export function useList({model, struct, goEdit, goDel}) {

    const [pending, setPending] = useState(false);
    const [objs, setObjs] = useState(null);
    const [pagination, setPagination] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [pageNum, setPageNum] = useState(0);
    const [search, setSearch] = useState('');
    const [sortCol, setSortCol] = useState(!struct ? null : struct.some(s => s.name === 'name') ? 'name' : '');
    const [sortOrder, setSortOrder] = useState('asc');

    async function load (modelParam) {
        setPending(true);
        modelParam = modelParam ? modelParam : model;
        setObjs(null);
        let url = '/' + modelToRoute(modelParam) + '?pageNum=' + pageNum + '&perPage=' + perPage;
        if (search && search.trim().length) {
            url += '&search=' + search.trim();
        }
        url += '&sortCol=' + sortCol + '&sortOrder=' + sortOrder;
        let res = await get(url, true);
        setPagination(res.headers);
        setObjs(res.data); 
        setPending(false);
    }

    useEffect(() => {load()}, [model, struct, pageNum, perPage, search, sortCol, sortOrder]);
    useEffect(() => {load()}, []);

    function setNewSearch (s) {
        setPageNum(0);
        setSearch(s);
    }

    function setNewPerPage (n) {
        if (n.target) {
            n = n.target.value;
        }
        n = Number(n);
        let firstIndex = pageNum * perPage;
        let newPageNum = Math.floor(firstIndex / n);
        setPageNum(newPageNum);
        setPerPage(n);
    }

    function setFirstPage () {
        setPageNum(0);
    }

    function setLastPage () {
        setPageNum(pagination.totalPages - 1);
    }

    function setNewSort (col) {
        if (sortCol === col) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        }
        else {
            setSortCol(col);
            setSortOrder('asc');
        }
        setPageNum(0);
    }

    function paginationFooter () {
        let arr = [];
        let spanSize = 3;
    
        // no pagination footer needed
        if (pagination.totalPages === 1) {
            return ([]);
        }
    
        // show first few pages
        for (let i=0; i<pagination.totalPages && i<spanSize; i++) {
            arr.push (i);
        }
    
        // show few pages around current page
        let startCurrRange = Math.floor(pagination.currPage - spanSize/2);
        let endCurrRange = Math.ceil(pagination.currPage + spanSize/2);
        for (let i=startCurrRange; i<=endCurrRange; i++) {
            if (i >= 0 && i < pagination.totalPages && !arr.includes(i)) {
                arr.push(i);
            }
        }
    
        // show last few pages
        startCurrRange = Math.floor(pagination.totalPages - 1 - spanSize/2);
        for (let i=startCurrRange; i<pagination.totalPages; i++) {
            if (i >= 0 && i < pagination.totalPages && !arr.includes(i)) {
                arr.push(i);
            }
        }
    
        // sort the page numbers
        arr.sort((a,b) => a>b?1:-1);
    
        return (arr);
    }

    function paginationSummary () {
        if (!pagination.totalFilteredCount) {
            return ('No matching items');
        }
        let str = 'Showing ' + (pagination.startIndex+1) + ' - ' + (pagination.endIndex+1) + ' out of ' + (pagination.totalFilteredCount);
        if (pagination.totalCount !== pagination.totalFilteredCount) {
            str += ' (filtered from ' + pagination.totalCount + ' total)';
        }
        return (str);
    }

    function val (obj, field) {
        
        let fieldStruct = struct.find(f => f.name === field || f.name === 'id_' + field);
        if (!fieldStruct) {
            //console.log('dbg crud-ui error: could not find struct for field ' + field + ' or id_' + field + ' in model ' + model);
            return;
        }

        let val = obj[field];

        if (fieldStruct.type === 'date') {
            if (val._isAMomentObject) {
                return (val.format('MMM D, YYYY'));
            }
            let m = moment(val);
            return (m.format('MMM D, YYYY'));
        }
        if (fieldStruct.type.includes('timestamp')) {
            if (val._isAMomentObject) {
                return (val.format('MMM D, YYYY h:MM:SS A'));
            }
            let m = moment(val);
            return (m.format('MMM D, YYYY h:MM:SS A'));
        }
        else if (val === true) {
            return ('true');
        }
        else if (val === false) {
            return ('false');
        }
        else if (val === null)
        {
            return (null);
        }
        if (typeof val === 'object')
        {
            if (val.name) {
                return (val.name)
            }
            else if (val.username) {
                return (val.username)
            }
            else if (val.content) {
                return (val.content);
            }
            else {
                return (val.id);
            }
        }
        if (field === 'when_inserted' || field === 'when_updated')
        {
            if (val._isAMomentObject) {
                return (val.format('MMM D, YYYY h:MM:SS A'));
            }
            let m = moment(val);
            return (m.format('MMM D, YYYY h:MM:SS A'));
        }
        return (val);
    }

    function prepareRow (row) {
        let ret = {};
        let keys = Object.keys(row);
        keys.forEach(key => {
            ret[key] = val(row, key);
        });
        ret.goEdit = () => goEdit(row);
        ret.goDel = () => goDel(row);
        return (ret);
    }

    function rows (func) {
        if (!func) {
            func = obj => obj;
        }
        if (!pending && objs) {
            let ret = objs.map((raw, i) => {
                let row = prepareRow(raw);
                let rendered = func(row, i, raw);
                return (rendered);
            });
            return (ret);
        }
        return (null);
    }
    
    return ({

        // data
        load,
        rows, 

        // search
        search: {
            set: setNewSearch,
            val: search,
            reset: () => setNewSearch(''),
        },

        // pagination
        pagination: !pagination ? {} : {
            showPagination: pagination.totalFilteredCount > pagination.perPage,
            currPage: pagination.currPage,
            totalPages: pagination.totalPages,
            startIndex: pagination.startIndex,
            endIndex: pagination.endIndex,
            perPage: pagination.perPage,
            totalCount: pagination.totalCount,
            totalFilteredCount: pagination.totalFilteredCount,
            setPage: setPageNum,
            setPerPage: setNewPerPage, 
            setFirstPage: (pagination.currPage === 0) ? null : setFirstPage,
            setLastPage: (pagination.currPage === pagination.totalPages - 1) ? null : setLastPage,
            setPrevPage: (pagination.currPage === 0) ? null : () => setPageNum(pagination.currPage - 1),
            setNextPage: (pagination.currPage === pagination.totalPages - 1) ? null : () => setPageNum(pagination.currPage + 1),
            summary: paginationSummary(),
            pageList: paginationFooter(),
        },

        // sort
        sort: {
            set: setNewSort,
            col: sortCol,
            order: sortOrder,

        },

    });
}