export default function PaginationFooter({ pagination }) {
  return (
    <>
      <div className="pagination">{pagination.summary}</div>

      { pagination.showPagination && 
        <div className="pagination bp">
          <span style={{ margin: "10px" }}>
            <button
              disabled={pagination.setFirstPage === null}
              onClick={pagination.setFirstPage}
            >
              First
            </button>
          </span>
          <span style={{ margin: "10px" }}>
            <button
              disabled={pagination.setPrevPage === null}
              onClick={pagination.setPrevPage}
            >
              Previous
            </button>
          </span>
          {pagination.pageList &&
            pagination.pageList.map((page, i) =>
              page === pagination.currPage ? (
                <span key={i} style={{ margin: "10px" }}>
                  <strong>{page + 1}</strong>
                </span>
              ) : (
                <span key={i} style={{ margin: "10px" }}>
                  <button onClick={() => pagination.setPage(page)}>
                    {page + 1}
                  </button>
                </span>
              )
            )}
          <span style={{ margin: "10px" }}>
            <button disabled={pagination.setNextPage === null} onClick={pagination.setNextPage}>
              Next
            </button>
          </span>
          <span style={{ margin: "10px" }}>
            <button disabled={pagination.setLastPage === null} onClick={pagination.setLastPage}>
              Last
            </button>
          </span>
        </div>
      }

    </>
  );
}
