import {useEffect} from 'react';
import AddEdit from './addEdit.js';
import assert from '../../helpers/assert.js';
import {ListView} from "../../components/list.js";
import {keyListener} from '../../App.js';

export default function List ({user, crud, list, setModel}) {

    useEffect(() => keyListener({
        'Escape': () => crud.close && crud.close(),
    }), [crud]);

    // assertions
    let rows = crud.list.rows(obj=>obj);
    if (rows && rows.length) assert(rows[0].organization, 'timeline_item has site');

    return (
        <ListView
            crud={crud} 
            user={user} 
            list={list} 
            setModel={setModel} 
            AddEdit={AddEdit}
            type="timeline_item"
            title="Timeline Items"
            addTitle="Add New Timeline Item"
            editTitle="Edit Timeline Item"
            fields={[
                { field:'timeline', friendly:'Timeline', showOnMobile:true },
                { field:'post', friendly:'Post', showOnMobile:true },
                { field:'position', friendly:'Position', showOnMobile:true },
                { field:'when_inserted', friendly:'Created', showOnMobile:false },
                { field:'when_updated', friendly:'Modified', showOnMobile:false },
            ]}
        />
    );
}