import {FullScreenDiv} from '../../components/fullScreenDiv.js';
import {AddEditDlg} from '../../components/addEditDlg.js';

export function AddPost ({session}) {

    if (!session.post.isAddingPost) {
        return (
            <button onClick={session.post.start} style={{backgroundColor:'black', color:'white', width:'200px'}}>ADD POST</button>
        );    
    }
    
    return (
        <FullScreenDiv onClick={session.post.cancel}>
            <AddEditDlg
                close={session.post.cancel}
                form={session.post.form}
                onAdd={session.post.submit}
                title="Add New Post"
                fields={[ 
                    { field:'title', friendly:'Title' },
                    { field:'content', friendly:'Content' },
                    { field:'id_network', friendly:'Network' },
                    { field:'url_image', friendly:'Image' }, 
                    { field:'url_video', friendly:'Video' }, 
                ]}                
            />
        </FullScreenDiv>
    );

}