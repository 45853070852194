import PleaseWait from "./pleaseWait.js";
import Delete from "./delete.js";
import LeftNav from "./leftNav.js";
import Header from "./header.js";
import AddBtn from "./addBtn.js";
import Search from "./search.js";
import PaginationHeader from "./paginationHeader.js";
import PaginationFooter from "./paginationFooter.js";
import editbtn from "../assets/images/icon-edit.svg";
import deletebtn from "../assets/images/icon-delete.svg";

function TableCell({ showOnMobile, value, imgref }) {
  const tooltip = document.getElementById("tooltip");

  document.querySelectorAll(".td-cell").forEach(cell => {
    cell.addEventListener("mouseenter", e => {
      tooltip.textContent = cell.getAttribute("data-full-text");
      tooltip.style.visibility = "visible";
    });

    cell.addEventListener("mousemove", e => {
      tooltip.style.top = `${e.clientY + 10}px`;
      tooltip.style.left = `${e.clientX + 10}px`;
    });

    cell.addEventListener("mouseleave", () => {
      tooltip.style.visibility = "hidden";
    });
  });

  return (
    <td
      id={showOnMobile ? "" : "rmmbl"}
      scope="row"
      className="td-cell"
      data-full-text={value}
    >
      {!imgref && value}
      {imgref && value !== "undefined" && (
        <img src={value} style={{ maxWidth: "50px", maxHeight: "50px" }} />
      )}
    </td>
  );
}

function TableActionsCell({ obj }) {
  return (
    <td className="td-edit">
      <div className="rows transparent">
        <a href="#" onClick={obj.goEdit}>
          <img
            src={editbtn}
            loading="lazy"
            width="24"
            alt="Edit"
            className="menu-icon"
            id="editicon"
          />
        </a>
        <a href="#" onClick={obj.goDel}>
          <img
            src={deletebtn}
            loading="lazy"
            width="24"
            alt="Delete"
            className="menu-icon"
            id="deleteicon"
          />
        </a>
      </div>
    </td>
  );
}

function TableActionsHeader() {
  return (
    <th scope="row" className="td-cell">
      <strong className="text-black">Actions</strong>
    </th>
  );
}

function TableHeader({ list, showOnMobile, field, friendly }) {
  return (
    <th
      onClick={() => list.sort.set(field)}
      id={showOnMobile ? "" : "rmmbl"}
      scope="row"
      className={"td-cell" + (list.sort.col === "username" ? " sort" : "")}
    >
      <strong className="text-black">
        {friendly}
        {list.sort.col === field && list.sort.order === "desc" ? " desc" : ""}
      </strong>
    </th>
  );
}

export function ListView({
  crud,
  user,
  type,
  list,
  setModel,
  title,
  AddEdit,
  addTitle,
  editTitle,
  pwdMsg,
  fields
}) {
  let rows = crud.list.rows();
  return (
    <>
      {crud.add && (
        <AddEdit close={crud.close} form={crud.add.form} title={addTitle} />
      )}
      {crud.edit && (
        <AddEdit
          close={crud.close}
          form={crud.edit.form}
          title={editTitle}
          pwdMsg={pwdMsg}
        />
      )}
      {crud.del && (
        <Delete crud={crud} close={crud.close} del={crud.del.del} type={type} />
      )}
      <div className="tooltip" id="tooltip"></div>
      <section className="section main">
        <LeftNav setModel={setModel} />
        <div className="div-grow login">
          <Header crud={crud} user={user} title={title} />
          <div className="content yscroll">
            <div className="space-between subheader">
              <div className="search btns">
                <AddBtn crud={crud} />
              </div>
              <div className="search">
                <Search list={list} />
              </div>
            </div>
            <PaginationHeader pagination={list.pagination} />
            {!rows ? (
              <PleaseWait />
            ) : (
              <div className="tablecontainer">
                <table className="table-css text-sm">
                  <thead>
                    <tr className="tr-row">
                      {fields.map((field, i) => (
                        <TableHeader
                          key={i}
                          list={list}
                          showOnMobile={field.showOnMobile}
                          field={field.field}
                          friendly={field.friendly}
                        />
                      ))}
                      <TableActionsHeader />
                    </tr>
                  </thead>
                  <tbody>
                    {list.rows((obj, i) => (
                      <tr
                        key={i}
                        className={i % 2 === 0 ? "tr-row" : "tr-row-even"}
                      >
                        {fields.map((field, i) => (
                          <TableCell
                            key={i}
                            showOnMobile={field.showOnMobile}
                            value={obj[field.field]}
                            imgref={field.imgref}
                          />
                        ))}
                        <TableActionsCell obj={obj} />
                      </tr>
                    ))}
                  </tbody>
                </table>
                <PaginationFooter pagination={list.pagination} />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
