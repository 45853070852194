import { AddEditDlg } from "../../components/addEditDlg";

export default function AddEdit ({close, form, title}) {
    return (
        <AddEditDlg 
            close={close} 
            form={form} 
            title={title} 
            pwdMsg={null} 
            fields={[ 
                { field:'id_network', friendly:'Network' },
                { field:'id_user', friendly:'User' },
                { field:'title', friendly:'Title' },
                { field:'content', friendly:'Content' },
                { field:'url_image', friendly:'Image' },
                { field:'url_video', friendly:'Video' },
            ]}
        />
    );
}
