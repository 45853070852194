import '../../assets/css/App.css';
import AdminDash from '../admin/dash.js';
import Session from '../session/index.js';

export default function Dash ({user}) {
    if (user.user.user_type.name === 'viewer') {
        return (<Session user={user} />);
    }
    else if (user.user.user_type.name === 'presenter') {
        return (<Session user={user} />);
    }
    else {
        return (<AdminDash user={user} />);
    }
}