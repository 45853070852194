import { AddEditDlg } from "../../components/addEditDlg";

export default function AddEdit ({close, form, title}) {
    return (
        <AddEditDlg 
            close={close} 
            form={form} 
            title={title} 
            pwdMsg={null} 
            fields={[
                { field:'name', friendly:'Name' },
                { field:'id_organization', friendly:'Site' },
                { field:'id_timeline_type', friendly:'Type' },
                { field:'auto_generate_posts', friendly:'Auto Generate Posts' },
            ]}
        />
    );
}
