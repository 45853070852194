import {FullScreenDiv} from "../../components/fullScreenDiv.js";

export function VideoPlayer ({session}) {
    return (
      session.video.isPlaying && (
        <FullScreenDiv onClick={session.video.stop}>
          <iframe
            style={{
              position: "absolute",
              left: "10vw",
              top: "10vh",
              width: "80vw",
              height: "80vh",
              backgroundColor: "black"
            }}
            src={session.video.url}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </FullScreenDiv>
      )
    );
}