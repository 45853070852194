import LogoRev from "../assets/images/logo-rev.png";
import IconLogo from "../assets/images/icon-logo-blue.png";
import IconDashboard from "../assets/images/icon-dashboard.svg";
import IconSite from "../assets/images/icon-site.svg";
import IconNetwork from "../assets/images/icon-network.svg";
import IconPost from "../assets/images/icon-post.svg";
import IconTimeline from "../assets/images/icon-timeline.svg";
import IconUsers from "../assets/images/icon-users.svg";

export default function LeftNav ({setModel}) {
  return (
    // Desktop Left Nav
    <div className="left-nav">
      <div className="div-grow-top">
        <img
          src={LogoRev}
          loading="lazy"
          width="175"
          alt="Agilion - Risk Management"
          className="logofull"
        />
        <img
          src={IconLogo}
          loading="lazy"
          width="40"
          alt="Agilion - Risk Management"
          className="logoicon"
        />
      </div>
      <div className="menu-container">
        <a href="#" className="menu-link w-inline-block" onClick={() => setModel('user')} >
          <img
            src={IconDashboard}
            loading="lazy"
            width="18"
            alt=""
            className="menu-icon"
          />
          <div className="navlink">Dashboard</div>
        </a>
        <a href="#" className="menu-link w-inline-block" onClick={() => setModel('organization')} >
          <img
            src={IconSite}
            loading="lazy"
            width="18"
            alt=""
            className="menu-icon"
          />
          <div className="navlink">Site</div>
        </a>
        <a href="#" className="menu-link w-inline-block" onClick={() => setModel('network')} >
          <img
            src={IconNetwork}
            loading="lazy"
            width="18"
            alt=""
            className="menu-icon"
          />
          <div className="navlink">Network</div>
        </a>
        <a href="#" className="menu-link w-inline-block" onClick={() => setModel('post')} >
          <img
            src={IconPost}
            loading="lazy"
            width="18"
            alt=""
            className="menu-icon"
          />
          <div className="navlink">Post</div>
        </a>
        <a href="#" className="menu-link w-inline-block" onClick={() => setModel('timeline')} >
          <img
            src={IconTimeline}
            loading="lazy"
            width="18"
            alt=""
            className="menu-icon"
          />
          <div className="navlink">Timeline</div>
        </a>
        <a href="#" className="menu-link w-inline-block" onClick={() => setModel('user')} >
          <img
            src={IconUsers}
            loading="lazy"
            width="18"
            alt=""
            className="menu-icon"
          />
          <div className="navlink">Users</div>
        </a>
      </div>
    </div>
  );
}