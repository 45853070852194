import {useState, useEffect} from 'react';
import {useLogin} from './login.js';
//import {Register, RegisterButton} from './register.js'; // ega change to hook
import Cookies from 'js-cookie';
import {post, clearToken} from '../fetch-fe/index.js';
import * as config from '../config-fe/index.js';

export function useUser (options = {}) {
  
  const [user, setUser] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const login = useLogin({
    didLogin: admit,
    cancel,
  });

  async function autoLogin () {
    let token = Cookies.get('token');
    if (token && token !== '') { 
      try {
        let user = await post('/user/auth', {token});
        setUser(user);
      }
      catch (error) {
        Cookies.remove('token', {path:''});
        if (config.get('user.goLoginWhenLoggedOut')) {
          setIsLoggingIn(true);
        }
      }
    }
    else {
      if (config.get('user.goLoginWhenLoggedOut')) {
        setIsLoggingIn(true);
      }
    }
  }

  useEffect(() => {
    autoLogin();
  }, []);

  function admit (user) {
    setUser(user);
    setIsLoggingIn(false);
    setIsRegistering(false);
  }

  async function logout () {
    await post('/user/logout');
    setUser(null);
    clearToken();
    if (options.didLogout) {
      options.didLogout();
    }
    if (config.get('user.goLoginWhenLoggedOut')) {
      setIsLoggingIn(true);
    }
  }

  function cancel () {
    setIsLoggingIn(false);
    setIsRegistering(false);
  }

  return ({
    user,
    isLoggingIn,
    isRegistering,
    login: () => setIsLoggingIn(true),
    logout,
    form: {
      login,
    },
    //Register: () => <Register didRegister={admit} cancel={cancel} />, // ega take this out and imports etc., replace with hoook 
    //RegisterButton: () => <RegisterButton onClick={() => setIsRegistering(true)} />, // ega take this out and imports etc., replace with callback
    //GreetingAndLogout: () => <GreetingAndLogout user={user} logout={logout} />, // ega take this out and imports etc., replace with callback and maybe just call it Logout
  });

}