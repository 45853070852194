import {FullScreenDiv} from './fullScreenDiv.js';

export function Confirm ({prompt, yes, no}) {
    prompt = prompt ? prompt : 'Are you sure?';
    return (
        <FullScreenDiv onClick={no}>
            <div style={{textAlign:'center', backgroundColor:'black', color:'white', width:'30vw', height:'20vh'}}>
                <div style={{marginBottom:'20px'}}>
                    {prompt}
                </div>
                <button style={{backgroundColor:'white', color:'black'}} onClick={yes}>Yes</button>
                <button style={{marginLeft:'10px', backgroundColor:'white', color:'black'}} onClick={no}>No</button>
            </div>
        </FullScreenDiv>
    );
}