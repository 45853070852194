import {AddComment} from './addComment.js';

export function Comments({ session, post, comments }) {

  function toggle () {
    session.comment.expand.toggle(post.id);
  }

  return (
    <div style={{ marginTop: "10px" }}>

      <a onClick={toggle}>
        Comments &nbsp;
        <span>({comments.length})</span>
      </a><br />

      { session.comment.expand.isExpanded(post.id) && comments.map((comment, i) => (
        <div key={i} style={{ margin: "10px" }}>
          {comment.comment} <br />
          <i>
            by {comment.username} - {comment.date}
          </i>
        </div>
      ))}

      {!session.comment.post.id && (
        <a
          style={{ textDecoration: "underline", marginLeft: "10px" }}
          onClick={() => session.comment.post.start(post.id)}
        >
          Leave a comment
        </a>
      )}

      {session.comment.post.id === post.id && (
        <AddComment
          pending={session.comment.pending}
          value={session.comment.value}
          set={session.comment.setValue}
          add={session.comment.submit}
          cancel={session.comment.post.cancel}
        />
      )}

    </div>
  );

}
