import './assets/css/App.css';
import { useUser } from './modules/user-ui/index.js';
import Login from './screens/user/login.js';
import Dash from './screens/dash/index.js';
import Chuck from './screens/chuck/index.js';
import ChuckCompare from './screens/chuckcompare/index.js';

let listeners = [];

export default function App() {
  const user = useUser();

  function key(e) {
    for (let i = 0; i < listeners.length; i++) {
      if (listeners[i].func) {
        listeners[i].func(e.key);
      } else if (listeners[i].obj) {
        let keys = Object.keys(listeners[i].obj);
        for (let j = 0; j < keys.length; j++) {
          let key = keys[j];
          if (key === e.key) {
            listeners[i].obj[key]();
          }
        }
      }
    }
  }

  if (window.location.href.includes('chuckcompare')) {
    return <ChuckCompare />;
  }
  if (window.location.href.includes('chuck')) {
    return <Chuck />;
  }
  if (user.isLoggingIn) {
    return (
      <div tabIndex={0} onKeyDown={key}>
        <Login form={user.form.login} />
      </div>
    );
  } else if (user.user) {
    return (
      <div tabIndex={0} onKeyDown={key}>
        <Dash user={user} />
      </div>
    );
  }
}

export function keyListener(objOrFunc) {
  if (typeof objOrFunc === 'function') {
    listeners.push({ func: objOrFunc });
  } else {
    listeners.push({ obj: objOrFunc });
  }
  return () => {
    listeners = [];
  };
}
