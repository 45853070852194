import {useEffect} from 'react';
import AddEdit from './addEdit.js';
import assert from '../../helpers/assert.js';
import {ListView} from "../../components/list.js";
import {keyListener} from '../../App.js';

export default function List ({user, crud, list, setModel}) {

    useEffect(() => keyListener({
        'Escape': () => crud.close && crud.close(),
    }), [crud]);

    // assertions
    let rows = crud.list.rows(obj=>obj);
    assert(rows && rows.length, 'post has rows');
    if (rows && rows.length) assert(rows[0].content, 'post has content');

    return (
        <ListView
            crud={crud} 
            user={user} 
            list={list} 
            setModel={setModel} 
            AddEdit={AddEdit}
            type="post"
            title="Posts"
            addTitle="Add New Post"
            editTitle="Edit Post"
            fields={[
                { field:'network', friendly:'Network', showOnMobile:true },
                { field:'user', friendly:'User', showOnMobile:true },
                { field:'title', friendly:'Title', showOnMobile:true },
                { field:'content', friendly:'Content', showOnMobile:true },
                { field:'url_image', friendly:'Image', imgref:true, showOnMobile:true },
                { field:'url_video', friendly:'Video', showOnMobile:false },
                { field:'when_inserted', friendly:'Created', showOnMobile:false },
                { field:'when_updated', friendly:'Modified', showOnMobile:false },
            ]}
        />
    );
}