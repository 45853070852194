import '../../assets/css/App.css';
import {useCrud} from '../../modules/crud-ui/index.js';
import UserList from '../user/list.js';
import NetworkList from '../network/list.js';
import PostList from '../post/list.js';
import OrgList from '../org/list.js';
import TimelineList from '../timeline/list.js';
import TimelineItemList from '../timeline_item/list.js';

export default function AdminDash ({user}) {
    const crud = useCrud('user');
    if (crud.model === 'user') {
        return (<UserList user={user} crud={crud} list={crud.list} setModel={crud.setModel} />);
    }
    else if (crud.model === 'network') {
        return (<NetworkList user={user} crud={crud} list={crud.list} setModel={crud.setModel} />);
    }
    else if (crud.model === 'post') {
        return (<PostList user={user} crud={crud} list={crud.list} setModel={crud.setModel} />);
    }
    else if (crud.model === 'organization') {
        return (<OrgList user={user} crud={crud} list={crud.list} setModel={crud.setModel} />);
    }
    else if (crud.model === 'timeline') {
        return (<TimelineList user={user} crud={crud} list={crud.list} setModel={crud.setModel} />);
    }
    else if (crud.model === 'timeline_item') {
        return (<TimelineItemList user={user} crud={crud} list={crud.list} setModel={crud.setModel} />);
    }
}