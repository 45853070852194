import {EmptyCard} from './emptyCard.js';
import {Comments} from './comments.js';

//ega maybe disable some functionality like showing comments, etc.
//ega replace with timer, design Rob ? and split this out to another file

function format (str) {
  return (str.split('\n').map((line, i) => <div key={i}>{line}</div>));
}

function PendingCard () {
  return (
    <div className="cards">
      <div className="rows-l-c">
        <div className="profile" />
        <div className="full-w" />
      </div>
      <p style={{marginBottom:'10px', fontStyle:'italic'}} />
      <p>Please wait...</p>
    </div>
  );
}

function Card ({post, video, session, sideView}) {
  return (
    <div className={sideView ? 'sidecards' : 'cards'}>
      <div className="rows-l-c">
        <div className="profile">
          <img src={post.url_logo} />
        </div>
        <div className="full-w">
          <h3>{sideView ? 'title' : post.title}</h3>
        </div>
      </div>
      { !sideView && <p style={{marginBottom:'10px', fontStyle:'italic'}}>Posted by: {post.username}</p> }
      <p>{format(sideView ? 'content' : post.content)}</p>
      { !sideView && post.url_video &&
          <button onClick={() => video.play(post.url_video)}>
            Play Video
          </button>
      }
      { post.url_image &&
          <img src={post.url_image} />
      }
      { !sideView &&
        <Comments
          session={session}
          post={post}
          comments={post.comments}
        /> 
      }
    </div>
  );
}

export function Cards({ sideView, allPosts, session, video, grid }) {
  if (sideView) {
    return ( 
      <div>
        { allPosts.map((post, j) => {
          let marker = session.pos === j-1 ? <div style={{width:'100%', height:'1px', backgroundColor:'black'}} /> : null;
          if (post === null) return (
            <>
              {marker}
              <EmptyCard key={j} />
            </>
          );
          if (!post.content) return (
            <>
              {marker}
              <PendingCard key={j} />
            </>
          );
          return (
            <>
              {marker}
              <Card sideView={sideView} key={j} post={post} video={video} session={session} />
            </>
          );
        })}
      </div>
    );
  }
  return (
    <div className="grid-container" style={{ overflowY: "scroll" }}>
      {grid.map((row, i) => (
        <div className="rows start gap" key={i}>
          {row.map((post, j) => {
            if (post === null) return (<EmptyCard key={j} />);
            if (!post.content) return (<PendingCard key={j} />);
            return (<Card sideView={sideView} key={j} post={post} video={video} session={session} />);
          }
        )}
        </div>
      ))}
    </div>
  );
}
