import {useState, useEffect} from 'react';
import {get, post, put} from '../fetch-fe/index.js';
import {useForm} from '../form-ui/index.js';
import moment from 'moment';
import {modelToRoute} from './helpers.js';

export function useAddEdit({model, done, target, struct}) {

    const [idLists, setIdLists] = useState(null);
    const [values, setValues] = useState(null);
    const [fields, setFields] = useState([]);
    const form = useForm({ submit }, fields);

    async function init ()
    {
        // read in allowed name/value pairs for foreign fields
        let lists = {};
        for (let i=0; i<struct.length; i++) {
            let s = struct[i];
            if (s.foreign) {
                let foreignModel = s.foreign.model.split('_').join('-');
                let ids = await get('/' + modelToRoute(foreignModel) + '/ids');
                lists[s.foreign.model] = ids;
            }
        }
        setIdLists(lists);

        // create an object with all the current values
        let valueObject = {};
        if (target)
        {
            for (let i=0; i<struct.length; i++) {
                if (struct[i].type.includes('timestamp')) {
                    target[struct[i].name] = moment(target[struct[i].name]);
                }
                if (struct[i].type === 'date') {
                    target[struct[i].name] = moment(target[struct[i].name]);
                }
            }
            valueObject = target;
        }
        else
        {
            struct.forEach(field => {
                if (field.foreign)
                {
                    valueObject[field.foreign.model] = lists[field.foreign.model][0];
                }
                else if (field.type.includes('timestamp')) {
                    valueObject[field.name] = moment();
                }
                else if (field.type === 'date') {
                    valueObject[field.name] = moment();
                }
                else if (field.type === 'boolean') {
                    valueObject[field.name] = false;
                }
                else
                {
                    valueObject[field.name] = '';
                }
            });
        }
        setValues(valueObject);

        structToFields(lists, valueObject);
    }

    useEffect(() => {
        init();
    }, [struct, target]);

    useEffect(() => {
        form.resetFields(fields);
    }, [fields]);
    
    function includeCol(s)
    {
        if (s.name === 'id') return (false);
        return (true);
    }
    
    function structToFields (idLists, values) {

        let filtered = struct.filter(includeCol);
        let mapped = filtered.map(s => {
            if (s.foreign) {
                return ({
                    input: 'select',
                    id: s.name,
                    label: s.foreign.model,
                    initialValue: values[s.foreign.model].id,
                    options: idLists[s.foreign.model],    
                });
            }
            else if (s.type.includes('timestamp')) {
                return ({
                    input: 'datetime',
                    id: s.name,
                    label: s.name,
                    initialValue: values[s.name],
                });
            }
            else if (s.type === 'date') {
                return ({
                    input: 'date',
                    id: s.name,
                    label: s.name,
                    initialValue: values[s.name],
                });
            }
            else if (s.type === 'boolean') {
                return ({
                    input: 'checkbox', 
                    id: s.name,
                    label: s.name,
                    initialValue: values[s.name],
                });
            }
            else {
                return ({
                    input: 'text',
                    id: s.name,
                    label: s.name,
                    initialValue: values[s.name],    
                });
            }
        });
        setFields(mapped);
    }

    async function doPut (obj)
    {
        // ega check bools here

        // get file upload, if any
        const fileInput = document.getElementById('uploaded_file'); // ega this is hardcoded, can i just look for url_media and whatever else?
        const file = !fileInput ? null : fileInput.files[0];

        // create body
        const body = new FormData();
        for (let i=0; i<struct.length; i++) {
            if (struct[i].name === 'id') {
                // do nothing
            }
            else if (struct[i].name === 'when_updated') {
                body.append('when_updated', moment().format('YYYY-MM-DD HH:mm:ss'));
            }
            else if (struct[i].type === 'date') {
                body.append(struct[i].name, obj[struct[i].name].format('YYYY-MM-DD'));
            }
            else if (struct[i].type.includes('timestamp')) {
                body.append(struct[i].name, obj[struct[i].name].format('YYYY-MM-DD HH:mm:ss'));
            }
            else if (struct[i].name === 'url_logo') { 
                if (file) {
                    body.append('uploaded_file', file); // ega hardcoded as "uploaded_file", but losing the field name in the process
                }
            }
            else if (struct[i].name === 'url_media') { 
                if (file) {
                    body.append('uploaded_file', file); // ega hardcoded as "uploaded_file", but losing the field name in the process
                }
            }
            else {
                body.append(struct[i].name, obj[struct[i].name]);
            }
        }
        try
        {
            //form.setError(null);// ega
            let res = await put('/' + modelToRoute(model) + '/' + target.id, body, false, false);
            // ega update interface
            done(res);
        }
        catch (error)
        {
            //form.setError('Encountered server error.');// ega
        }
    }

    async function doPost (obj)
    {
        // ega check bools here
        try {
            // get file upload, if any
            const fileInput = document.getElementById('uploaded_file'); // ega this is hardcoded, can i just look for url_media and whatever else?
            const file = !fileInput ? null : fileInput.files[0];

            // create body
            const body = new FormData();
            for (let i=0; i<struct.length; i++) {
                if (struct[i].name === 'id') {
                    // do nothing
                }
                else if (struct[i].type === 'date') {
                    body.append(struct[i].name, obj[struct[i].name].format('YYYY-MM-DD'));
                }
                else if (struct[i].type.includes('timestamp')) {
                    body.append(struct[i].name, obj[struct[i].name].format('YYYY-MM-DD HH:mm:ss'));
                }
                else if (struct[i].name === 'url_logo') { 
                    if (file) {
                        body.append('uploaded_file', file); // ega hardcoded as "uploaded_file", but losing the field name in the process
                    }
                }
                else if (struct[i].name === 'url_media') { 
                    if (file) {
                        body.append('uploaded_file', file); // ega hardcoded as "uploaded_file", but losing the field name in the process
                    }
                }
                else {
                    body.append(struct[i].name, obj[struct[i].name]);
                }
            }

            //form.setError(null);// ega
            const res = await post('/' + modelToRoute(model), body, false, false);
            // ega update interface
            done(res);
        } catch (error) {
            //form.setError('Encountered server error.');// ega
            //alert('An error occurred while uploading the file.');// ega
        }
    }

    async function submit (obj)
    {
        if (target)
        {
            await doPut(obj);
        }
        else
        {
            await doPost(obj);
        }
    }

    return ((!idLists || !values) ? null : {
        form,
   });

}