export function PickSession ({session}) {
    return (
        <div>
          <h3>Pick a Session:</h3>
          {session.matchingSessions.map((s, i) => (
            <div key={i}>
              <button onClick={() => session.setSession(s)}>
                {s.name} ({s.id})
              </button>
            </div>
          ))}
        </div>
      );  
}