import * as Config from '../config-common/index.js';

export function use (config, env)
{
   if (global.config)
   {
      console.log('Error: attempt to set config, but it is already set.');
   }
   else
   {
      global.config = {
         config,
         env,
      };
   }
}

export function env ()
{
   if (!global.config)
   {
      console.log('Error: attempt to get env, but it has not been set yet');
      return (null);
   }
   else
   {
      return (global.config.env);
   }
}

export function get (str, defaultValue)
{
   if (!global.config)
   {
      console.log('Error: attempt to use config, but it has not been set yet');
      return (null);
   }
   else
   {
      return (Config.get(str, global.config.env, global.config.config, defaultValue));
   }
}
