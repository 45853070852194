import logorev from "../../assets/images/logo-rev.png";
import logouticon from "../../assets/images/icon-logout.svg";

export function Header ({session}) {
    return (
      <div className="div-min-header">
        <div>
          <img src={logorev} loading="lazy" width="145" alt="Agilion" />
        </div>
        <div>
          <a
            className="menu-link presenter w-inline-block"
            onClick={session.logout}
          >
            <div className="navlink">Logout&nbsp;</div>
            <img
              src={logouticon}
              loading="lazy"
              width="14"
              alt="Logout"
              className="menu-icon"
            />
          </a>
        </div>
      </div>
    );
}